import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Container from '../../components/shared/Container';
import SEO from '../../components/shared/SEO';
import introVocabCover from '../../images/ebooks/kumadex-introductory-vocab.png';
import preview00 from '../../images/ebooks/kumadex-introductory-vocab-preview00.png';
import preview01 from '../../images/ebooks/kumadex-introductory-vocab-preview01.png';
import preview02 from '../../images/ebooks/kumadex-introductory-vocab-preview02.png';
import preview03 from '../../images/ebooks/kumadex-introductory-vocab-preview03.png';
import preview04 from '../../images/ebooks/kumadex-introductory-vocab-preview04.png';
import preview05 from '../../images/ebooks/kumadex-introductory-vocab-preview05.png';
import preview06 from '../../images/ebooks/kumadex-introductory-vocab-preview06.png';
import preview07 from '../../images/ebooks/kumadex-introductory-vocab-preview07.png';
import preview08 from '../../images/ebooks/kumadex-introductory-vocab-preview08.png';

const Breadcrumbs = () => (
  <nav className="flex" aria-label="Breadcrumb">
    <ol className="flex items-center space-x-4">
      <li>
        <div>
          <Link to="/" className="text-gray-400 hover:text-gray-500">
            <svg className="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
            </svg>
            <span className="sr-only">Home</span>
          </Link>
        </div>
      </li>
      <li>
        <div className="flex items-center">
          <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>
          <Link to="/ebooks" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">eBooks</Link>
        </div>
      </li>
      <li>
        <div className="flex items-center">
          <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>
          <Link to="/ebooks/kumadex-jlpt-n5-50-words" aria-current="page" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Learn Introductory Vocab</Link>
        </div>
      </li>
    </ol>
  </nav>
);

const EbookPreview = ({ imgSrc, imgAlt, caption }) => (
  <li>
    <div className="space-y-4">
      <div className="aspect-w-3 aspect-h-2">
        <img className="object-cover shadow-lg rounded-lg" src={imgSrc} alt={imgAlt} />
      </div>

      <div className="space-y-2">
        <div className="text-lg leading-6 font-medium space-y-1">
          <p className="text-green-600">{caption}</p>
        </div>
      </div>
    </div>
  </li>
);

const Ebook = () => (
  <div>
    <SEO
      title="Kuma Learn - Introductory Japanese vocab with pictures eBook"
      description="Learn introductory Japanese Vocabulary with Pictures. Get it today."
    />

    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Learn introductory Japanese vocab with pictures",
            "image": [
              "/images/ebooks/kumadex-introductory-vocab.jpg"
             ],
            "description": "Introductory Japanese Vocabulary with Pictures. Get it today.",
            "brand": {
              "@type": "Brand",
              "name": "Kuma Learn"
            }
          }
        `}
      </script>
    </Helmet>

    <div className="container px-5 mx-auto">
      <div className="lg:w-4/5 mx-auto my-4">
        <Breadcrumbs />
      </div>
    </div>

    <section className="text-gray-600 body-font overflow-hidden">
      <div className="container px-5 pt-10 pb-24 mx-auto">
        <div className="lg:w-4/5 mx-auto flex flex-wrap">
          <img alt="Learn Introductory Japanese Vocab with Pictures" className="lg:w-1/2 w-full lg:h-auto h-64 object-contain object-center rounded" src={introVocabCover} />
          <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
            <h2 className="text-lg leading-6 font-medium space-y-1">Kumadex</h2>
            <h1 className="text-green-600 text-3xl title-font font-medium mb-4">Learn introductory Japanese vocab with pictures eBook</h1>
            <p className="leading-relaxed mb-6">
              Packed with 400+ words with pictures, cultural notes and more,
              this eBook is your companion for visual learning.
              Get it today.
            </p>

            <ul className="mb-8 space-y-5">
              <li className="flex items-start lg:col-span-1">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                  </svg>
                </div>
                <p className="ml-3 text-sm text-gray-700">
                  Learn visually
                </p>
              </li>

              <li className="flex items-start lg:col-span-1">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                  </svg>
                </div>
                <p className="ml-3 text-sm text-gray-700">
                  Culture notes
                </p>
              </li>

              <li className="flex items-start lg:col-span-1">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                  </svg>
                </div>
                <p className="ml-3 text-sm text-gray-700">
                  How-tos
                </p>
              </li>
            </ul>

            <div className="flex">
              <span className="title-font font-medium text-2xl text-gray-900">$7.99</span>
              <a
                href="https://gum.co/kuma-learn-intro-japanese-vocab-with-pictures"
                target="_blank"
                rel="noreferrer noopener"
                className="flex ml-auto text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded"
              >
                Buy eBook
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="container px-5 mx-auto">
      <div className="lg:w-4/5 mx-auto my-4">
        <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
          <EbookPreview
            imgSrc={preview00}
            imgAlt="Learn Introductory Japanese Vocab with Pictures - Preview"
            caption="Contents"
          />
          <EbookPreview
            imgSrc={preview01}
            imgAlt="Learn Introductory Japanese Vocab with Pictures - Preview"
            caption="Contents (continued)"
          />
          <EbookPreview
            imgSrc={preview02}
            imgAlt="Learn Introductory Japanese Vocab with Pictures - Preview"
            caption="How to read the eBook"
          />
          <EbookPreview
            imgSrc={preview03}
            imgAlt="Learn Introductory Japanese Vocab with Pictures - Preview"
            caption="Learn vocabulary with pictures"
          />
          <EbookPreview
            imgSrc={preview04}
            imgAlt="Learn Introductory Japanese Vocab with Pictures - Preview"
            caption="Special culture notes"
          />
          <EbookPreview
            imgSrc={preview05}
            imgAlt="Learn Introductory Japanese Vocab with Pictures - Preview"
            caption="Learn vocabulary with pictures"
          />
          <EbookPreview
            imgSrc={preview06}
            imgAlt="Learn Introductory Japanese Vocab with Pictures - Preview"
            caption="Special how-to notes"
          />
          <EbookPreview
            imgSrc={preview07}
            imgAlt="Learn Introductory Japanese Vocab with Pictures - Preview"
            caption="Learn vocabulary with pictures"
          />
          <EbookPreview
            imgSrc={preview08}
            imgAlt="Learn Introductory Japanese Vocab with Pictures - Preview"
            caption="Grammar reference pages"
          />
        </ul>
      </div>
    </div>
  </div>
);

const JlptN5MiniEbookPage = () => (
  <Container>
    <Ebook />
  </Container>
);

export default JlptN5MiniEbookPage;
